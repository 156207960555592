/* Tutorial Carousel style*/
.tutorial-screen{
    /*width: 100%;*/
    margin: auto;
    background-image: url("../../assets/img/tutorial/tutorialbg.png");
    background-repeat: repeat-x;
}

.tutorial-title{
    font-weight: bold;
    color: white;
}

.tutorial-titledesc{
    color: white;
}

.tutorial-imgdiv{
    width: 50%;
    display:inline-block;
}

.tutorial-descdiv{
    position: absolute;
    top: 20%;
    display:inline-block;
}

.tutorial-titlemobile{
    font-size: 25px;
    font-weight: bold;
    text-align: left;
}

.tutorial-titledescmobile{
    font-size: 18px;
    text-align: left;
}

.tutorial-imgdivmobile{
    display:inline-block;
}

.tutorial-descdivmobile{
    display:inline-block;
    margin-left: 10%;
    color:white;
}

.tutorial-imgstyle{
    width: inherit;
    height: auto;
}

@media screen and (min-width: 1800px) {
    .tutorial-title{
        font-size: 30px;
    }
    .tutorial-titledesc{
        font-size: 20px;
    }
    .tutorial-screen{
        background-size: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1799px){
    .tutorial-title{
        font-size: 2.2vw;
    }
    .tutorial-titledesc{
        font-size: 1.25vw;
    }
    .tutorial-screen{
        background-size: 100%;
    }
}

@media screen and (max-width: 699px){
    .tutorial-screen{
        background-repeat: round;
        text-align: center;
        background-size: cover;
    }
}

button.ext-owl-nav {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    width: 30px;
    height: 30px;
    opacity: 0.75;
    background-color: #869791 !important;
    border-radius: 50% !important;
  }
  
  button.ext-owl-nav:before {
    content: none;
  }
  
  button.ext-owl-nav:hover {
    width: 30px;
    height: 30px;
    opacity: 0.75;
    background-color: white !important;
    border-radius: 50% !important;
  }
  
  button.ext-owl-nav > span {
    font-size: 30px;
    line-height: 20px;
    font-weight: bold;
    color: black !important;
  }
  
  button.ext-owl-nav.ext-owl-prev {
    left: 2px;
    outline: none;
    z-index: 99;
  }
  
  button.ext-owl-nav.ext-owl-next {
    right: 2px;
    outline: none;
    z-index: 99;
  }

