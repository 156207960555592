/* dropdown */
.header-language-selection {
  position: absolute;
  display: none;
  margin: 0;
  list-style-type: none;
  background-color:#00364d;
  min-width:250px;
  padding:10px 20px;
  border-radius:5px;
}

.language-wrapper .label {
  color: #e6e6e6;
  margin-bottom: 10px;
}

.language-wrapper .item {
  width:100%;
  padding: 10px 0;
}

.lang-dropdown {
  background-color:black;
  position:fixed;
}

.lang-dropdown .option.ant-select-dropdown-menu-item-selected,
.lang-dropdown .option.ant-select-dropdown-menu-item-active
{
  background-color:#292929;
}

.language-wrapper .ant-select-selection,
.language-wrapper .ant-select-arrow {
  background-color:black;
  color: #e6e6e6;
}

.language-wrapper .option,
.lang-dropdown .option {
  padding: 10px;
  color: #e6e6e6;
}

.lang-radiogroup .ant-radio-button-wrapper {
  display: block !important;
  height: 45px !important;
  line-height: 45px !important;
  color: rgba(255, 255, 255, 0.65);
  border: none;
  box-sizing: inherit;
  background-color: #242623;
}

.lang-radiogroup .ant-radio-button-wrapper:hover,
.lang-radiogroup .ant-radio-button-wrapper-checked {
  background-color: #000000 !important;
  box-shadow: inherit;
  color: rgba(255, 255, 255, 1.0);
}

.lang-radiogroup .ant-radio-button-wrapper::before {
  background-color: #242623 !important;
  left: 0px !important;
  width: 0px !important;
}

.language-wrapper .flag,
.lang-dropdown .flag {
  width:30px;
  height:auto;
  margin-right: 10px;
  float:left;
}

.language-wrapper .flag {
  position:relative;
  top:5px;  
}

.language-wrapper .value,
.lang-dropdown .value {
  float: left;
}

.language-wrapper .ant-btn {
  width:100%;  
}

.header-language-selection.open {
  display: block;
}


/* overlay */
.language-popup-background.open {
  display: none !important
}

.language-popup-background {
position: fixed;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.8);
z-index: 999;
top: 0;
display: none;
}

.language-popup-background.open {
  display: block;
}

.language-popup {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}

.language-popup-menu {  
margin: 0px;
min-width: 300px;
padding: 20px;
list-style-type: none;
background-color: #1a1b19;
}

@media (max-width:991px) {
  .language-popup-background.open {
    display: block !important
  }
}
