
/* Tutorial Pop up */
.tutorial-popup-background.open {
  display: block
}

.tutorial-close-button {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}

.tutorial-overview {
  width: 1020px;
  height: 360px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-position: center center;
  background-size: cover;
}

.tutorial-table {
  width: 700px;
  display: table;
  margin: auto;
  padding-top: 25px;
}

  .tutorial-table > div {
      display: table-cell;
      vertical-align: middle;
      padding: 0 5px;
  }

.tutorial-popup-title {
  color: white;
  font-size: 40px;
}

.tutorial-popup-description {
  color: white;
  font-size: 14px;
}

.tutorial-overview .carousel-control > .fa {
  font-size: 200px;
  transform: translate(0%,-50%);
  margin: 0;
  color: white;
}

.tutorial-overview .carousel-indicators li {
  margin: 0 20px !important;
  width: 15px !important;
  height: 15px !important;
  background-color: rgba(78, 78, 78,0.5)
}

  .tutorial-overview .carousel-indicators li.active {
      background-color: white
  }

.tutorial-image {
  height: 335px;
  display: inline-block
}

@media (max-width: 1199px) {
  .tutorial-overview {
      width: 90%
  }

  .tutorial-table {
      width: auto;
  }

      .tutorial-table > div {
          display: block
      }

          .tutorial-table > div:nth-child(2) {
              background-color: white
          }

  .tutorial-popup-title {
      color: #323232;
      font-size: 30px;
  }

  .tutorial-popup-description {
      color: #323232;
      font-size: 12px;
      padding: 0 15px 5px;
  }

  .tutorial-table > div {
      text-align: center;
  }

  .tutorial-overview .carousel-control {
      display: none
  }


  .tutorial-overview {
      height: auto;
  }

  .tutorial-carousel-button {
      position: relative;
      padding-top: 51px;
      background-color: white
  }

  .tutorial-overview .carousel-indicators li {
      margin: 0 3px !important;
      width: 15px !important;
      height: 15px !important;
  }

      .tutorial-overview .carousel-indicators li.active {
          background-color: #323232
      }
}

@media (max-width: 500px) {
    .tutorial-screen .slick-prev{
        left: 2px !important;
    }

    .tutorial-screen .slick-next{
        right: 2px !important;
    }
}