.member-table-header {
    position:sticky;
    top:0;
}

.member-table-header > tr > th {
    font-weight: bold;
    padding: 0.5em;
    border: 1pt solid #25AAE1;
    background-color: #25AAE1;
    color: white;
    height:40px;
    font-size:14px;
}

.member-table-row > tr > td {
    padding: 0.5em;
    font-size: 14px;
    color: #25AAE1;
    border: 1pt solid #25AAE1;
}

.member-table-container{
    height:375px;
    overflow-y:auto;
    white-space:nowrap;
}

.member-table-message{
    color:red;
    text-align:center;
}

.member-table-records {
    color: #25AAE1;
}