.error_body {
	margin: 0 auto;
	width: 960px;
}

.error_bg li { list-style-type: none; }

.error_bg ul, .error_bg ol, .error_bg dl {
	margin: 0;
	padding: 0;
}

.error_bg optgroup {
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
}

.error_bg a:title { color: #fff; }

.error_bg h1, .error_bg h2, .error_bg h4, .error_bg h5, .error_bg h6 {
	color: #fff;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 20px;
	font-weight: bold;
	margin-top: 0;
	padding-left: 0px;
}

.error_bg h3 {
	
	color: #000;
	font-family: "Arial", "Regular", sans-serif;
	font-size: 14px;
}

.error_bg p {
	color: #000;
	font-family: "Arial", "Regular", sans-serif;
	font-size: 18px;
}

.error_bg a img, .error_bg_browser a img, .error_bg_javascript a img{
	border: none;
	text-decoration: none;
}

.error_bg a:link {
	color: #25aae1;
	font-family: "Arial", "Regular", sans-serif;
	font-weight: bold;
	text-decoration: none;
}

.error_bg a:visited {
	color: #25aae1;
	font-family: "Arial", "Regular", sans-serif;
	text-decoration: none;
}

.error_bg a:hover {
	color: #999;
	font-family: "Arial", "Regular", sans-serif;
	text-decoration: none;
}

.error_bg .color_2 { color: #F00; }

.error_head_photo {
	left: 62px;
	position: absolute;
	top: 96px;
}

.error_head_photo_ad {
	left: 80px;
	top: 90px;
}

.error_head_photo_404 {
	left: 72px;
	top: 88px;

}

.error_head_photo_js {
	left: 68px;
	position: absolute;
	top: 103px;
}

.error_head_photo_browser {
	left: 95px;
	position: absolute;
	top: 103px;
}

.error_head_photo_size{
	width:30%;
	height:30%;
}

.error_tel {
	left: 98px;
	position: absolute;
	top: 242px;
}

.error_email {
	left: 99px;
	position: absolute;
	top: 283px;
}

.error_bg {
	height: 700px;
	position: relative;
}



.error_desc{
	float:left;
	margin-top:3%;
	text-align: center;
	width:50%;
}
.error_bg_404{
	background-image: url('../../assets/img/error/NotFound/NotFoundBG.jpg');
	top:0;
	width:100%;
	height: 100%; 
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: auto;
}
.error_bg_accessdenied{
	background-image: url('../../assets/img/error/AccessDenied/AccessDeniedBG.jpg');
	top:0;
	width:100%;
	height: 100%; 
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: auto;
}
.error_bg_maintenance{
	background-image: url('../../assets/img/error/Maintenance/MaintenanceBG.jpg');
	top:0;
	width:100%;
	height: 100%; 
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: auto;
}
.error_contentChar{
	text-align: right;
	margin-left: 50%;
}

  .error_char{
	max-width: 70%;
	height:100%;
  }

  .error_logo{
	  width:20%;
	  height:auto;
	  /* margin-left: 25%;
	  margin-top: 2%; */
  }
 .error_div{
	/*  width:50%;
	 height:auto; */
 	 margin-left: 3%;
	 margin-top: 4%; 
	 
	 display: inline-block;
 }
 .error_content,
 .error_text{
	display:inline-block;
	vertical-align:middle;
	
 }
 .error_content{
	height:auto;
	width:15%;
 }
 .error_text{
	 /* float:right; */
	 text-align: center;
	 color: #25AAE1;
	 font-size: 3.5em;
	 max-width: font;
	 font-weight: 500; 
	 width:85%;
 }
 .error_text1{
	 margin-left: 4%;
	 margin-top: 5%;
	 color:black;
	 font-size: 2.1em;
	 text-align: center;
	 white-space: pre-line;
 }
 .error_contactdetails{
	/* float:right; */
	text-align: center;
	color: #25AAE1;
	font-size: 2.3em;
	max-width: font;
	font-weight: 500; 
	margin-top: 3%;

}
.error_div1{
	margin-left:16%;
	margin-top: 2%;
	width: 100%;
	height: 100%;
}

.error_contactinfo{
	width: 100%;
	height: auto;
	float:right;
}
.error_contactinfo1{
	width: 100%;
	height: auto;
	float:left;
}

.error_contactcontainer{
	position: relative;
	text-align: center;
	float:left;
	width:50%; 
}
.error_contactcontainer1{
	margin-left:50%;
}

.error_container{
  position: relative;
  text-align: center;
  width:100%;
  margin-left: 3%;
}

.bottomleft{
	position: absolute;
	bottom: 60px;
	left: 40px;
	font-size: 1.4rem;
	color:white;
	text-decoration: underline;
	-webkit-text-underline-position: under;
	-ms-text-underline-position: below;
	text-underline-position: under;
}
@media only screen and (max-width:1200px){
	.bottomleft{
		position: absolute;
		bottom: 50px;
		left: 30px;
		font-size: 1rem;
		color:white;
		text-decoration: underline;
		-webkit-text-underline-position: under;
		-ms-text-underline-position: below;
		text-underline-position: under;
	}
}
@media only screen and (max-width:500px){
	.bottomleft{
		position: absolute;
		bottom: 30px;
		left: 20px;
		font-size: .6rem;
		color:white;
		text-decoration: underline;
		-webkit-text-underline-position: under;
		-ms-text-underline-position: below;
		text-underline-position: under;
	}
}
@media only screen and (max-width:850px){
	.error_bg_404{
		background-image: url('../../assets/img/error/NotFound/NotFoundBG-Mobile.jpg');
		top:0;
 		width:100%;
		height: 100%;  
		background-size: cover;
		 background-position: center; 
		background-repeat: no-repeat;
		 overflow: auto; 
	}
	.error_bg_accessdenied{
		background-image: url('../../assets/img/error/AccessDenied/AccessDeniedBG-Mobile.jpg');
		top:0;
		width:100%;
		height: 100%; 
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		overflow: auto;
	}
	.error_bg_maintenance{
		background-image: url('../../assets/img/error/Maintenance/MaintenanceBG-Mobile.jpg');
		top:0;
		width:100%;
		height: auto; 
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		
	}
	.error_logo{
		width: 35%;
		height:auto;
		text-align: right;
	}
	.error_desc{
		margin-top:5%;
		margin-left: 0;
		width:100%;
	}
	.error_char{
		max-width: 50%;
		height:auto;
	  }
	.error_div{
		/*  width:50%;
		 height:auto; */
		  margin-left: 0;
		 margin-top: 0; 
		 
		 display: inline-block;
	 }
	 .error_content,
	 .error_text{
		display:inline-block;
		vertical-align:middle;
		
	 }
	 .error_content{
		height:auto;
		width:15%;
		
	 }
	 .error_text{
		 text-align: center;
		 color: #25AAE1;
		 font-size: 2.5em;
		 font-weight: 540; 
		 width:75%;
	 }
	 .error_text1{
		 margin-top: 4%;
		 color:black;
		 font-size: 1.4em;
		 text-align: center;
		 white-space: pre-line;
	 }
	 .error_contactdetails{
		/* float:right; */
		text-align: center;
		color: #25AAE1;
		font-size: 1.2em;
		max-width: font;
		font-weight: 500; 
		margin-top: 3%;
	}
	.error_div1{
		margin-top: 2%;
		width: 100%;
		height: 100%;
	}
	
	.error_contactinfo{
		max-width: 100%;
		height: auto;
	}
	.error_contentChar{
		text-align: center;
		margin-left: 0;
	}
}


.error_bg_access_denied {
	background: url(https://www.fun678.com/lm/images/ErrorPages/Fun88_pe_bg.jpg) no-repeat;
	height: 625px;
	position: relative;
}

.error_bg_javascript {
	background: url(https://www.fun678.com/lm/images/ErrorPages/Fun88_popup.jpg) no-repeat;
	height: 700px;
	left: 50%;
	margin-left: -395px;
	margin-top: 15px;
	position: relative;
}

.error_bg_browser {
	background: url(https://www.fun678.com/lm/images/ErrorPages/Fun88_popup_modify_bg.jpg) no-repeat;
	height: 700px;
	left: 50%;
	margin-left: -395px;
	position: relative;
	width: 100%;
}

.error_a_txt {
	height: 30px;
	left: 81px;
	position: absolute;
	top: 359px;
	width: 550px;
}

.error_a_txt_js { top: 332px; }

.error_txt {
	height: 80px;
	left: 295px;
	position: absolute;
	top: 430px;
	width: 531px;
}

.error_txt_js {
	height: 80px;
	left: 211px;
	top: 384px;
	width: 495px;
}

.error_txt_browser {
	height: 80px;
	left: 211px;
	top: 365px;
	width: 500px;
}

.error_txt_browser p {
	font-size: 12px;
	font-weight: bold;
	height: 50px;
	overflow: hidden;
}

#browser_container {
	margin-top: -20px;
	position: relative;
}

.browser_icon {
	display: block;
	float: left;
	text-align: center;
	top: 55px;
	vertical-align: top;
	width: 125px;
}

.browser_icon img {
	height: 67px;
	width: 72px;
}

.browser_icon h3 {
	font-size: 11px;
	margin-top: -2px;
}

.button_fun88 
{
    background: url(/images/ErrorPages/Fun88_popup_modify_bg_middle.jpg) repeat;
	display: block;
	position: relative;
	text-align: center;
	top: 0;  /*568px;*/
	width: 780px;
	margin-top: 0;
	margin-bottom: 0;
}

.button_fun88 img {
	height: 36px;
	width: 200px;
}

.button_goback 
{
    background: url(/images/ErrorPages/Fun88_popup_modify_bg_middle.jpg) repeat;
	display: block;
	position: relative;
	text-align: center;
	top:  0; /*562px;*/
	width: 780px;
	margin-top: 0;
	margin-bottom: 0;	
}

.button_goback p {
	font-size: 12px;
	font-weight: bold;
}

.error_button {
	left: 28px;
	position: absolute;
	top: 22px;
}

.error_button_browser { top: 26px; }

#error_browser_body
{
    position:absolute;
    top:365px;
    background: url(/images/ErrorPages/Fun88_popup_modify_bg_middle.jpg) repeat;
}
.error_browser_footer
{
    background: url(/images/ErrorPages/Fun88_popup_modify_bg_bottom.jpg) no-repeat;
    position:relative;
    top:0;
}
.dvTitle
{
    margin-left:70px;
}

/* Browser Not Supported */
#iconWarning {
    
    float: left;
    width: 80px;
    height: 90px;
    position: relative;
    padding-left: 10px;
    padding-top: 10px;
}

.BP_content {
    height: 100px;
    margin-bottom:15px;
}

.BP_header {
    background-color: #25AAE1;
    color: #FFFFFF;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14pt;
    height: 39px;
    margin: 0 auto;
    padding: 0;
    width: 510px;
}

#dvContent1 {
    padding-top: 15px;
    /*font-weight: bold;*/
    margin-bottom: 5px;
    margin-left: 10px;
    width: 410px;
    float: left;
    height:100px;
}

#spContent1 {
    font-weight: bold;
    padding-bottom: 10px;
}
#dvContent2 
{
    float: left;
    width: 80%;
    margin-left: 10px;
    
    margin-bottom: 10px;
}

#spLink {
    color: #25AAE1;
    
}

#BP_notification_box {
    background: white;
    border-radius: 5px 5px 5px 5px;
    height: 211px;
    /*margin: 0 auto;*/
    padding-top: 5px;
    width: 520px;
    overflow: hidden;
}

.BP_header_text {
    color: #FFFFFF;
    float: left;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 13pt;
    margin-left: 11px;
    margin-top: 10px;
}


.BP_signup .BP_button {
    display: inline;
    float: left;
    font-size: 12px;
    font-weight: bold;
    height: 35px;
    /*line-height: 0;*/
    margin-left: 3px;
    width: 123px;
}

.BP_login_btn {
    height: 35px;
    margin-left:auto;
	margin-right:auto;
    width: 255px;    
    
}

.error_wrapper {
	position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
}
