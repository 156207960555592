.ant-modal-header {
  word-break: break-word;
  padding: 16px 40px 16px 24px;
}

.ant-modal-content .ant-modal-header {
  background-color: #27AAE1;
}

.ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
}

.ant-modal-content .ant-modal-close-icon {
  color: #fff;
}

.popup-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 9999;
  top: 0;
  display: none;
}

  .popup-background.open {
      display: block;
  }

.popup-main-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 450px;
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 100%;
}

.popup-title-box {
  padding: 10px 30px;
  background-color: #27AAE1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.popup-title {
  color: white;
  font-size: 22px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.popup-close-button {
  position: absolute;
  right: 25px;
  top: 15px;
  color: white;
  font-size: 40px;
  cursor: pointer;
  width: 20px;
}

.popup-box {
  background-color: white;
  padding: 20px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.popup-message {
  color: #838383;
  font-size: 14px;
  margin: 0;
  line-height: 1.2;
  text-align: center;
}

.popup-channel-box {
  padding: 15px 0;
}

.popup-channel-line {
  font-size: 24px;
  margin: 0;
  text-align: center;
}

.popup-form-title {
  margin: 0;
  font-weight: bold;
}

.popup-input-container {
  border: 1px solid #27AAE1;
  position: relative;
  padding: 0px;
  margin: 12px 0 0px;
}

  .popup-input-container.change-password {
      margin: 0px 0px 12px
  }

  .popup-input-container > input {
      width: 100%;
      border: 0;
      text-indent: 10px;
      display: inline-block;
      margin: 0;
      box-shadow: none;
      padding: 10px 5px;
      line-height: initial;
      border: 0;
      height: auto;
      font-size: 13px;
      max-width: unset;
      text-align: center;
  }

.popup-button-box {
  display: table;
  width: 100%;
  table-layout: fixed;
}

  .popup-button-box > div {
      display: table-cell;
  }

      .popup-button-box > div:nth-child(odd) {
          padding-right: 8px;
      }

      .popup-button-box > div:nth-child(even) {
          padding-left: 8px;
      }


.popup-resubmit-box {
  margin: 15px 0 5px;
  text-align: center;
}

.popup-form-button, .popup-form-cancel-button {
  cursor: pointer;
  transition: background-color .25s ease-out,color .25s ease-out;
}

.popup-form-button {
  background-color: #27AAE1;
}

  .popup-form-button:hover {
      background-color: #00456A;
  }

      .popup-form-button:hover > p {
          color: white
      }

.popup-form-cancel-button:hover {
  background-color: #d9d9d9
}


  .popup-form-button > p, .popup-form-cancel-button > p {
      color: black;
      padding: 8px;
      text-align: center;
      margin: 0;
      color: #00456A;
      font-weight: bold;
      font-size: 14px;
      transition: background-color .25s ease-out,color .25s ease-out;
  }

.popup-resubmit-box {
  margin: 15px 0 5px;
  text-align: center;
}

.popup-resubmit-button-box {
  margin-top: 5px;
}

  .popup-resubmit-button-box > span {
      color: #27AAE1;
      padding: 6px 8px;
      cursor: pointer;
      text-decoration: underline;
      font-size: 14px;
      transition: color .25s ease-out;
  }

      .popup-resubmit-button-box > span:hover {
          color: #00456A
      }

.popup-main-box.bank-account-info-container {
  width: 350px
}

.popup-title-box.bank-account-info-box {
  background-color: white;
  border-bottom: 1px solid #C6C6C6
}

.popup-title.bank-account-name {
  color: #323232;
  text-align: left
}

.popup-close-button.bank-account-close {
  font-size: 25px;
  top: 13px;
  color: #C6C6C6
}

.popup-bank-account-edit-button {
  background-color: #00456A;
}

  .popup-bank-account-edit-button > p {
      color: white
  }

.popup-bank-account-delete-button > span {
  color: red;
  font-weight: bold;
  text-decoration: underline;
}

.popup-bank-account-image-box {
  background-image: url(http://via.placeholder.com/158x90);
  background-position: center center;
  height: 110px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-size: cover;
}

.bank-account-popup-box {
  padding-left: 0;
  padding-right: 0;
}

.popup-bank-account-button-box {
  padding: 20px 30px
}

.popup-bank-account-detail-table {
  display: block;
  table-layout: fixed;
  padding: 20px 30px;
}

  .popup-bank-account-detail-table > div {
      display: block;
      width: 100%;
      position: relative;
      text-align: left;
  }

      .popup-bank-account-detail-table > div > div > p {
          color: #00456A
      }

          .popup-bank-account-detail-table > div > div > p:last-child {
              float: right;
              color: black
          }

      .popup-bank-account-detail-table > div > div > p {
          font-weight: bold;
          font-size: 14px;
          display: inline-block;
      }

.popup-big-icon {
  font-size: 150px;
  text-align: center;
  line-height: 1;
  margin-bottom: 15px;
}

  .popup-big-icon.success {
      color: #119D00
  }

@media only screen and (max-width: 480px) {
  .popup-title-box, .popup-box, .popup-main-box {
      border-radius: 0px;
  }

  .popup-title {
      font-size: 16px;
  }

  .popup-popup,
  .popup-main-box {
      width: 100%
  }

      .popup-main-box.bank-account-info-container {
          width: 90%;
      }

  .popup-button-box > div {
      display: block;
      padding: 0 !important;
      margin-bottom: 5px;
  }

  .popup-bank-account-button-box > div {
      display: table-cell;
  }

      .popup-bank-account-button-box > div:nth-child(odd) {
          padding-right: 8px !important;
      }

      .popup-bank-account-button-box > div:nth-child(even) {
          padding-left: 8px !important;
      }


  .popup-close-button {
      right: 15px;
      top: 10px;
      font-size: 30px;
  }
}
