.form-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 0 15px;
}

.label-wrapper {
    margin-bottom:10px;
}

.label-wrapper .label {
    color: #00456A;
    font-size: 15px;
    font-weight: bolder;
    white-space: pre-wrap;
}

.label-wrapper .desc {
    font-size:12px;
    word-break: break-word;
    white-space: pre-wrap;
}